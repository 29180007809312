<template>
	<div class="topHeader_box" :class="{'line':isShowline}">
		<div class="backicon" @click="$emit('goBack')" v-if="isShowBack">
			<img class="icon" src="../../assets/static/leftgo.png"/>
			<div class="text fontsize24">返回</div>
		</div>
		<div class="imglogobox">
			<img class="img100" src="../../assets/static/Group34657821.png"/>
		</div>
	</div>
</template>
<script>
	import {
		mapState
	} from 'vuex'
	export default {
		name: 'topHeader',
		props: {
			titlename: {
				type: String,
				default: () => {
					return "顶部导航";
				}
			},
			isShowBack: {
				type: Boolean,
				default: () => {
					return true;
				}
			},
			//下划线
			isShowline: {
				type: Boolean,
				default: () => {
					return true;
				}
			},
		},
		computed: {
			...mapState(['isLogin','bsnName']),
		},
		data() {
			return {
				
			};
		},
		created() {

		},
		watch: {
			isShow(data) {
				this.dialogFormRegister = data
			}
		},
		methods: {
			
		}
	};
</script>
<style lang="scss" scoped>
	.topHeader_box{
		height: 80px;
		display: flex;
		align-items: center;
		justify-content: space-between;
		position: relative;
		z-index: 2;
		&.line{
			border-bottom: 1px solid #E5E5E5;;
		}
		.backicon{
			display: flex;
			align-items: center;
			cursor: pointer;
			padding-top: 12px;
			.icon{
				width: 24px;
				height: 24px;
			}
			.text{
				margin-left: 5px;
				color: #999;
			}
		}
		.imglogobox{
			width: 522px;
			height: 33px;
			flex-shrink: 0;
		}
	}
</style>